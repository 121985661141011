import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const handleScroll = () => {
    const section = document.getElementById( 'kontakt' );
    section.scrollIntoView( { behavior: 'smooth'} );
};

const contactData = {
    en: {
        title: "Contact us",
        history: <Fragment>
            Do you have something on your mind? Would you like to find something out from us? Don’t be afraid to write to us! <br />You can write us a scroll and send it to  <a href="mailto:info.de@captaincandy.eu">info.de@captaincandy.eu</a>, or fill in an entry in  <button className="button-underline" onClick={() => handleScroll()}>our logbook bellow</button>.
            </Fragment>,
        historyLink: <Fragment>If you’re interested in getting a franchise, <Link to="/franchising">go here</Link> to find out more.
        </Fragment>,
        questionTitle: "Send a question or request",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "What is it?",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send message"
    },
    nl: {
        title: "Neem contact met ons op",
        history: <Fragment>
            Heb je iets aan je hoofd? Wil je iets van ons weten? Wees niet bang om ons te schrijven!
            <br />
            U kunt ons een boekrol schrijven en opsturen naar <a href="mailto:info.de@captaincandy.eu">info.de@captaincandy.eu</a>, of vul een invoer in <button className="button-underline" onClick={() => handleScroll('kontakt')}>ons logboek hieronder</button>.
            </Fragment>,
        historyLink: <Fragment>Als u geïnteresseerd bent in het krijgen van een franchise, <Link to="/franchising">ga hier</Link> om meer te weten te komen.
        </Fragment>,
        questionTitle: "Stuur een vraag of verzoek",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Naam en achternaam",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefoon nummer",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Wat is het?",
                textarea: true,
            },
        ],
        link: <Fragment>“Ik stem in met de <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">verwerking van mijn persoonsgegevens</Link> en de antwoorden op de vragenlijst.</Fragment>,
        button: "Bericht versturen"
    },
    de: {
        title: "Kontaktiere uns",
        history: <Fragment>
            Haben Sie etwas auf dem Herzen? Sie möchten etwas von uns erfahren? Scheuen Sie sich nicht, uns zu schreiben! <br />Sie können uns eine Schriftrolle schreiben und an <a href="mailto:info.de@captaincandy.eu">info.de@captaincandy.eu</a> senden oder einen Eintrag in <button className="button-underline" onClick={() => handleScroll()}>unten unser Logbuch</button>.
            </Fragment>,
        historyLink: <Fragment>Wenn Sie an einer Franchise interessiert sind, <Link to="/franchising">gehen Sie hier</Link>, um mehr zu erfahren.</Fragment>,
        questionTitle: "Senden Sie eine Frage oder Anfrage",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name und Nachname*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefon*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Warum möchten Sie mit Captain Candy zusammenarbeiten?",
                textarea: true,
            },
        ],
        link: <Fragment>“Ich stimme den Bedingungen der <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">Verarbeitung personenbezogener Daten</Link> und den Antworten im Fragebogen zu.“</Fragment>,
        button: "ABSENDEN"
    },
};

export {
    contactData,
}