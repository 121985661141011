import React from "react";
import hpBox from "../img/hp_box_1-min.jpg";
import hpBox1 from "../img/hp_box_2-min.jpg";
import hpBox2 from "../img/hp_box_3-min.jpg";
import hpBox3 from "../img/hp_box_4-min.jpg";

const boxes = {
    title: {
        en: "From Captain Candy’s board",
        nl: "Van het dek van Captain Candy",
        de: "Von Captain Candys Board",
    },
    en: [
        {
            img: hpBox,
            title: <React.Fragment>Can you smell the sweetness in the air?</React.Fragment>,
            description: "Temptation at its best. Discover this world of sweets with us.",
            link: "More information",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: <React.Fragment>All the places we anchor</React.Fragment>,
            description: "You can now find us in four places in Prague!",
            link: "More information",
            href: "/stores"
        },
        {
            img: hpBox2,
            title: <React.Fragment>The end of sour looks!</React.Fragment>,
            description: "Do people give you sour looks? Try offering them our sour jelly beans!",
            link: "More information",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox3,
            title: <React.Fragment>Find out more about what we do</React.Fragment>,
            description: "Discover the journey of a Czech company that brings joy from all over the world.",
            link: "More information",
            href: "/about"
        },
    ],
    nl: [
        {
            img: hpBox,
            title: <React.Fragment>Stinkt het?<br />Jazeker!</React.Fragment>,
            description: "Zelfs als iets stinkt, kan het nog goed nieuws zijn",
            link: "Meer informatie",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: <React.Fragment>Alle plaatsen waar we voor anker gaan</React.Fragment>,
            description: "Je kunt ons nu vinden op twee plekken in Praag.",
            link: "Meer informatie",
            href: "/products"
        },
        {
            img: hpBox2,
            title: <React.Fragment>Het einde van zure blikken!</React.Fragment>,
            description: "Kijken de mensen je zuur aan? Kijk eens of ze onze zure jelly beans willen proeven.",
            link: "Meer informatie",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox3,
            title: <React.Fragment>Ontdek meer over wat we doen<br /></React.Fragment>,
            description: "Ontdek de reis van een Tsjechisch bedrijf dat je lekkers brengt uit heel de wereld.",
            link: "Meer informatie",
            href: "/products"
        },
    ],
    de: [
        {
            img: hpBox,
            title: <React.Fragment>Kannst du die Süße in der Luft riechen?</React.Fragment>,
            description: "Versuchung vom Feinsten. Entdecken Sie mit uns diese Welt der Süßigkeiten.",
            link: "Mehr Informationen",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: <React.Fragment>Alle Orte, an denen wir ankern</React.Fragment>,
            description: "Sie finden uns jetzt an zwei Orten in Prag.",
            link: "Mehr Informationen",
            href: "/stores"
        },
        {
            img: hpBox2,
            title: <React.Fragment>Das Ende der sauren Blicke!</React.Fragment>,
            description: "Sehen Sie die Leute sauer an? Versuchen Sie, ihnen unsere sauren Gelee-Bohnen anzubieten.",
            link: "Mehr Informationen",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox3,
            title: <React.Fragment>Erfahren Sie mehr darüber, was wir tun</React.Fragment>,
            description: "Entdecken Sie die Reise eines tschechischen Unternehmens, das Sie um die ganze Welt bringt",
            link: "Mehr Informationen",
            href: "/about"
        },
    ],
};

const HpMapData = {
    en: {
        title: "Our store in Cologne",
        button: {
            name: "More information",
        }
    },
    nl: {
        title: "Onze winkel in Cologne",
        button: {
            name: "More information",
        }
    },
    de: {
        title: "Unsere Geschäftsstelle in Köln",
        button: {
            name: "Mehr Informationen",
        }
    }
};
    
export { boxes, HpMapData };
    