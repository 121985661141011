import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { newsletter } from '../../assets/dummyData/popupNewsletter';
import { validateEmail } from '../../assets/function';
import ModalEmailSuccess from '../ModalEmailSuccess/ModalEmailSuccess';
import { axiosLink } from '../../axiosLink';
import LoaderMail from '../LoaderMail/LoaderMail';
import { Context } from '../../context/Products';

const PopupNewsletter = () => {
    const [{ lang }] = useContext(Context);
    const [showModalEmailSuccess, setShowModalEmailSuccess] = useState(true);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState();
    const [hasError, setHasError] = useState();
    const [emailExist, setEmailExist] = useState(false);
    const [required, setRequired] = useState(false);
    const [disabledSend, setDisabledSend] = useState(true);
    const body = document.body;

    const closeModal = (event) => {
        if (event.target.id === "modal-popup-newsletter" || event.target.id === "modal-popup-newsletter-span") {
            body.classList.remove("modal-open");
            setLoading(true)
        }
    }
    const onchange = (e) => {
        setFormData(e.target.value);
        if (required) {
            const valid = validateEmail(e.target.value);
            if (valid) {
                setHasError(false);
            } else {
                setHasError(true);
            }
        }
    }
    const sendEmail = (event) => {
        event.preventDefault();
        const valid = validateEmail(formData);
        if (valid) {
            setHasError(false);
            setRequired(false);
            setDisabledSend(false);
            axios.post(`${axiosLink}/mail/newsletter`, { email: formData, state: "de", lang: lang }).then((data) => {
                if (data?.data?.emailExist) {
                    setEmailExist(true);
                    setTimeout(() => {
                        setEmailExist(false);
                    }, 7000);
                } else {
                    setShowModalEmailSuccess(false);
                    setLoading(true);
                }
                setDisabledSend(true);
            }).catch((err) => {
                console.log("eeer", err);
            })
        } else {
            setRequired(true)
            setHasError(true);
        }
    }

    const currentDateGet = (getFullDate) => {
        const fullDate = getFullDate.split("/")
        const fullDate1 = fullDate[2].split(",")
        const dateOn = [fullDate[0],  fullDate[1], fullDate1[0]];
        return dateOn;
    }

    const checkForPopupNewsletter = () => {
        const getFullDate = new Date().toLocaleString('en-US', { timeZone: "Europe/Prague" })
        const getDate = localStorage.getItem("popup");
        if (getFullDate && getDate) {
            const splitdate = getDate.split(",")
            const currentDate = currentDateGet(getFullDate);

            if ((currentDate[0] > splitdate[0]) || (currentDate[1] > splitdate[1]) || (currentDate[2] > splitdate[2]) ) {
                localStorage.setItem("popup", currentDate);
                body.classList.add("modal-open");
                setLoading(false);
            }
        } else {
            setLoading(false);
            body.classList.add("modal-open");
            localStorage.setItem("popup", currentDateGet(getFullDate));
        }
        return '';
    };
    
    useEffect(() => {
        setTimeout(() => {
            checkForPopupNewsletter();
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        showModalEmailSuccess ? (
            <div id="modal-popup-newsletter" className="modal in fade fade-onload" role="dialog" style={loading ? { display: "none" } : { display: "block" }} onClick={(e) => closeModal(e)}>
                <div className="modal-dialog">
                    {/* <!-- Modal content--> */}
                    <div className="modal-content on-click">
                        <span className="close" id="modal-popup-newsletter-span" data-dismiss="modal" onClick={(e) => closeModal(e)}></span>
                        <div className="modal-body">
                            <div className="content">
                                <div className="inner">
                                    <span className="ttl">{newsletter[lang].title}</span>
                                    <span className="subttl">{newsletter[lang].subttl}</span>
                                    <div className="component-newsletter">
                                        <form className="input-wrap" onSubmit={sendEmail}>
                                            <div className="input-group has-feedback">
                                                {/* <input type="text" className="form-control" id="newsletter_input" onChange={onchange} placeholder={newsletter[lang].placeholder} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" /> */}
                                                <input type="text" className="form-control" id="newsletter_input" onChange={onchange} placeholder={newsletter[lang].placeholder} />
                                                <span className="input-group-btn">
                                                    <button type="submit" className={`btn ${!disabledSend ? "disabled" : "btn-primary"}`} id="newsletter_btn" data-newsletter-register-btn="">{disabledSend ? newsletter[lang].button : <LoaderMail />}</button>
                                                </span>
                                            </div>
                                        </form>
                                        {/* <!-- IF error, ELSE open #modal-email-success bud pres JS(https://www.w3schools.com/bootstrap/bootstrap_ref_js_modal.asp) nebo pres btn viz nahore --> */}
                                        <span className={`error text-red ${!hasError && "hidden"}`}>
                                            {newsletter[lang].error}  
                                        </span>
                                        {/* <!-- /IF error --> */}
                                        <span className={`error text-red ${!emailExist && "hidden"}`}>
                                            {newsletter[lang].emailExist}  
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        : (
            <ModalEmailSuccess open />
        )
    )
};

export default PopupNewsletter;