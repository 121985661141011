const newsletter = {
    en: {
        title: "WANT TO BE THE FIRST?",
        subttl: "Back in the olden days, you couldn’t get hold of exclusive information without having to undergo torture, walk the plank or be subjected to any keelhauling. These days you just need to send us an e-mail and hey presto!",
        placeholder: "Enter your e-mail address",
        button: "Sing up",
        error: "Body of a thousand whales! This email address has already been entered by someone else! Enter another email address.",
        emailExist: "Body of a thousand whales! This email address has already been entered by someone else! Enter another email address.",
    },
    de: {
        title: "WOLLEN SIE DER ERSTE SEIN?",
        subttl: "In früheren Zeiten konnte man keine exklusiven Informationen erhalten, ohne gefoltert, über die Planken zu gehen oder sich einem Kielholen zu unterziehen. Heutzutage brauchen Sie uns nur noch eine E-Mail zu senden und schon ist es so weit!",
        placeholder: "Geben sie ihre E-Mailadresse ein",
        button: "Registrieren",
        error: "Körper von tausend Walen! Diese E-Mail-Adresse wurde bereits von jemand anderem eingegeben! Geben Sie eine andere E-Mail-Adresse ein.",
        emailExist: "Körper von tausend Walen! Diese E-Mail-Adresse wurde bereits von jemand anderem eingegeben! Geben Sie eine andere E-Mail-Adresse ein.",
    },
};

export {
    newsletter
};